
import { computed, defineComponent } from 'vue';
import { IEstrutura } from '@/models/Entidades/IEstrutura';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import storeApp from '@/store/storeApp';

export default defineComponent({
  name: 'Seo',
  props: {
    tituloExemplo: {
      type: String,
      default: 'Título conteúdo',
    },
    descricaoExemplo: {
      type: String,
      default: 'A Descrição será gerada automaticamente conforme o seu conteúdo',
    },
    estrutura: {
      type: Object as ()=> IEstrutura,
      required: true,
    },
  },
  emits: ['update:estrutura'],
  setup(props, { emit }) {
    const computedEstrutura = computed({
      get: () => props.estrutura,
      set: (estrutura: IEstrutura) => {
        emit('update:estrutura', estrutura);
      },
    });

    function obtemTituloSeo() {
      if (UtilitarioGeral.valorValido(computedEstrutura.value.titulo)) {
        return computedEstrutura.value.titulo;
      }

      return props.tituloExemplo;
    }

    function obtemDescricaoSeo() {
      if (UtilitarioGeral.valorValido(computedEstrutura.value.descricao)) {
        return computedEstrutura.value.descricao;
      }

      return props.descricaoExemplo;
    }

    function obtemUrlSeo() {
      if (UtilitarioGeral.valorValido(computedEstrutura.value.url)) {
        return `https://${storeApp.state.configuracaoPlataforma.dominio}/${computedEstrutura.value.url}`;
      }
      return `https://${storeApp.state.configuracaoPlataforma.dominio}/url`;
    }

    return {
      props,
      storeApp,
      computedEstrutura,
      obtemTituloSeo,
      obtemDescricaoSeo,
      obtemUrlSeo,
    };
  },
});
