
import {
  defineComponent, onBeforeMount, reactive,
} from 'vue';
import { useAppBase } from '@/core/composables/AppBase';
import { IConteudo } from '@/models/Entidades/IConteudo';
import ComunicacaoApi from '@/core/components/UI/ComunicacaoApi.vue';
import EditorHtml from '@/core/components/UI/EditorHtml.vue';
import Seo from '@/components/Seo.vue';
import ServicoPost from '@/servicos/ServicoPost';
import { IResposta } from '@/core/models/IResposta';
import { IEstrutura } from '@/models/Entidades/IEstrutura';
import { ETipoEstrutura } from '@/models/Enumeradores/ETipoEstrutura';
import { ETipoConteudo } from '@/models/Enumeradores/ETipoConteudo';

export default defineComponent({
  name: 'PostCadastro',
  components: {
    ComunicacaoApi,
    EditorHtml,
    Seo,
  },
  setup() {
    const {
      appBase, apresentarComunicacaoApi, apresentarRespostaRedirecionamento, obterCodigoRota,
    } = useAppBase();
    const servicoPost = new ServicoPost();

    const state = reactive({
      pagina: {} as IConteudo,
    });

    async function obterConteudo(codigo:number) {
      appBase.carregando = true;
      state.pagina = await servicoPost.obter(codigo);
      appBase.carregando = false;
    }

    function limparDados() {
      state.pagina = {} as IConteudo;
      state.pagina.codigo = 0;
      state.pagina.tipo = ETipoConteudo.Blog;
      state.pagina.status = true;
      state.pagina.estrutura = {} as IEstrutura;
      state.pagina.estrutura.codigo = 0;
      state.pagina.estrutura.indexar = true;
      state.pagina.estrutura.tipo = ETipoEstrutura.Blog;
    }

    onBeforeMount(async () => {
      limparDados();
      const codigoConteudo = obterCodigoRota();
      if (codigoConteudo > 0) {
        await obterConteudo(codigoConteudo);
      }
    });

    async function salvar() {
      appBase.resposta = {} as IResposta;
      apresentarComunicacaoApi('Aguarde por favor... Estamos salvando as informações do post.');
      if (state.pagina.codigo === 0) {
        state.pagina.estrutura.url = 'url';
        appBase.resposta = await servicoPost.incluir(state.pagina);
      } else {
        appBase.resposta = await servicoPost.atualizar(state.pagina);
      }

      apresentarRespostaRedirecionamento('Posts');
    }
    return {
      appBase,
      state,
      salvar,
    };
  },
});
